import { gql } from '@apollo/client';
import axios, { AxiosInstance } from 'axios';
import FormData from 'form-data';
import * as process from 'process';
import { BlogType, DirectusFile, EventType, JobType, NewsType } from 'types';

import { AppEnv } from '../../utils/accessEnviroment';
import { findTopFiveFrequentSkills } from '../../utils/helper';
import {
  GET_ALL_BLOGS_PATHS,
  GET_ALL_EVENTS_PATHS,
  GET_ALL_EVENTS_QUERY,
  GET_ALL_JOBS_PATHS,
  GET_ALL_NEWS_PATHS,
  GET_ALL_NEWS_QUERY,
  GET_ALL_OPENING_DEPARTMENTS,
  GET_BLOG_BY_SLUG,
  GET_EVENT_BY_SLUG,
  GET_HOT_NEWS,
  GET_JOB_BY_SLUG,
  GET_LATEST_TOP_BLOG,
  GET_NEWS_BY_SLUG,
  GET_RECOMMEND_SKILLS,
  GET_RELATED_BLOGS_BY_HASHTAG,
  GET_RELATED_EVENTS_BY_HASHTAG,
  GET_RELATED_NEWS_BY_HASHTAG,
  GET_STAR_EVENTS,
  GET_SUPER_HOT_OPENING_JOBS,
  GET_TOP_EVENTS,
  GET_TOTAL_NUMBER_BLOGS_BY_USER_ID,
  GET_TOTAL_NUMBER_EVENTS_BY_USER_ID,
  GET_TOTAL_NUMBER_NEWS_BY_USER_ID,
  GET_TOTAL_NUMBER_OPENING_JOBS,
  getAllBlogsQuery,
  getAllOpeningJobsQuery
} from './directus.query';
import {
  BlogsAggregated,
  EventAggregated,
  JobsAggregated,
  NewsAggregated
} from './directus.type';
import { apolloClient } from './setup';

export function getDirectusImg(cover_image: DirectusFile) {
  return `${AppEnv.DIRECTUS_URL}/assets/${cover_image.id}`;
}

export async function getSuperHotOpeningJobs(limit: number | undefined) {
  try {
    const { data } = await apolloClient.query<{
      jobs: JobType[];
    }>({
      query: GET_SUPER_HOT_OPENING_JOBS,
      variables: {
        limit
      }
    });
    return data.jobs;
  } catch (error) {
    return [];
  }
}

export async function getAllOpeningJobs(
  address?: string[],
  workType?: string[],
  department?: string[],
  skill?: string[],
  title?: string,
  page: number = 1,
  limit: number = 10
) {
  const query = gql(
    getAllOpeningJobsQuery(address, workType, department, skill, title)
  );
  try {
    const { data } = await apolloClient.query<{
      jobs: JobType[];
      jobs_aggregated: JobsAggregated[];
    }>({
      query,
      variables: {
        address,
        workType,
        department,
        skill,
        title,
        page,
        limit
      }
    });
    return {
      jobs: data.jobs,
      totalCount: data.jobs_aggregated[0].count.id
    };
  } catch (error) {
    return {
      jobs: [],
      totalCount: 0
    };
  }
}

export async function getAllOpeningDepartment() {
  const { data } = await apolloClient.query<{
    jobs: { department: string }[];
  }>({
    query: GET_ALL_OPENING_DEPARTMENTS
  });
  const departments = data.jobs.map(job => job.department);
  return [...new Set(departments)];
}

export async function getRecommendSkills() {
  const { data } = await apolloClient.query<{
    jobs_skills: { skills_id: { name: string } }[];
  }>({
    query: GET_RECOMMEND_SKILLS
  });
  return findTopFiveFrequentSkills(data.jobs_skills);
}

export async function getTotalNumberOpeningJobs() {
  try {
    const { data } = await apolloClient.query<{
      jobs_aggregated: JobsAggregated[];
    }>({ query: GET_TOTAL_NUMBER_OPENING_JOBS });
    return data.jobs_aggregated[0].count.id;
  } catch (error) {
    return null;
  }
}

export async function getAllJobsPaths() {
  try {
    const { data } = await apolloClient.query<{
      jobs: JobType[];
    }>({
      query: GET_ALL_JOBS_PATHS
    });
    return data.jobs.map(job => ({ params: { slug: job.slug } }));
  } catch (error) {
    console.log(error);
  }
}

export async function getJobBySlug(slug: string) {
  try {
    const { data } = await apolloClient.query<{
      jobs: JobType[];
    }>({
      query: GET_JOB_BY_SLUG,
      variables: {
        slug
      }
    });
    return data.jobs[0];
  } catch (error) {
    console.log(error);
  }
}

export async function getAllEvents(page: number = 1, limit: number = 3) {
  const { data } = await apolloClient.query<{
    events: EventType[];
    events_aggregated: EventAggregated[];
  }>({
    query: GET_ALL_EVENTS_QUERY,
    variables: {
      page,
      limit
    }
  });
  return { events: data.events, events_aggregated: data.events_aggregated };
}

export async function getHotNews(limit: number = 1) {
  try {
    const { data } = await apolloClient.query<{
      news: NewsType[];
    }>({
      query: GET_HOT_NEWS,
      variables: {
        limit
      }
    });
    return data.news;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllNews(
  latestHotNewsIds: string[] = [],
  page: number = 1,
  limit: number = 6
) {
  try {
    const { data } = await apolloClient.query<{
      news: NewsType[];
      news_aggregated: NewsAggregated[];
    }>({
      query: GET_ALL_NEWS_QUERY,
      variables: {
        latestHotNewsIds,
        page,
        limit
      }
    });
    return { news: data.news, totalCount: data.news_aggregated[0].count.id };
  } catch (error) {
    return { news: [], totalCount: 0 };
  }
}

export async function GetAllNewsPaths() {
  try {
    const { data } = await apolloClient.query<{
      news: NewsType[];
    }>({
      query: GET_ALL_NEWS_PATHS
    });
    return data.news.map(p => ({ params: { slug: p.slug } }));
  } catch (error) {
    console.log(error);
  }
}

export async function GetNewsBySlug(slug: string) {
  try {
    const { data } = await apolloClient.query<{
      news: NewsType;
    }>({
      query: GET_NEWS_BY_SLUG,
      variables: {
        slug
      }
    });
    return data.news[0];
  } catch (error) {
    console.log(error);
  }
}

export async function GetTotalCountNewsByUserID(userID: string) {
  try {
    const { data } = await apolloClient.query<{
      news_aggregated: NewsAggregated[];
    }>({
      query: GET_TOTAL_NUMBER_NEWS_BY_USER_ID,
      variables: { userID }
    });
    return data.news_aggregated[0].count.id;
  } catch (error) {
    console.log(error);
  }
}

export async function GetAllEventPaths() {
  try {
    const { data } = await apolloClient.query<{
      events: EventType[];
    }>({
      query: GET_ALL_EVENTS_PATHS
    });
    return data.events.map(p => ({ params: { slug: p.slug } }));
  } catch (error) {
    console.log(error);
  }
}

export async function GetEventBySlug(slug: string) {
  try {
    const { data } = await apolloClient.query<{
      events: EventType;
    }>({
      query: GET_EVENT_BY_SLUG,
      variables: {
        slug
      }
    });
    return data.events[0];
  } catch (error) {
    console.log(error);
  }
}

export async function GetTotalNumberEventsByUserID(userID: string) {
  try {
    const { data } = await apolloClient.query<{
      events_aggregated: NewsAggregated[];
    }>({ query: GET_TOTAL_NUMBER_EVENTS_BY_USER_ID, variables: { userID } });
    return data.events_aggregated[0].count.id;
  } catch (error) {
    console.log(error);
  }
}

export async function getTopEvents(limit: number = 1) {
  try {
    const { data } = await apolloClient.query<{
      events: EventType;
    }>({
      query: GET_TOP_EVENTS,
      variables: {
        limit
      }
    });
    return data.events[0];
  } catch (error) {
    return {};
  }
}

export async function GetStarEvent(limit: number = 5) {
  try {
    const { data } = await apolloClient.query<{
      events: EventType[];
    }>({
      query: GET_STAR_EVENTS,
      variables: {
        limit
      }
    });
    return data.events;
  } catch (error) {
    console.log(error);
  }
}

export async function GetRelatedNewsByHashtag(
  relatedHashtagArray: string[],
  originNewsId: string,
  page: number = 1,
  limit: number = 3
) {
  try {
    const { data } = await apolloClient.query<{
      news: NewsType[];
      news_aggregated: {
        countDistinct: {
          id: number;
        };
      }[];
    }>({
      query: GET_RELATED_NEWS_BY_HASHTAG,
      variables: { relatedHashtagArray, originNewsId, limit, page }
    });
    return {
      news: data.news,
      totalCount: data.news_aggregated[0].countDistinct.id
    };
  } catch (error) {
    console.log(error);
  }
}

export async function GetRelatedEventsByHashtag(
  relatedHashtagArray: string[],
  originEventId: string,
  page: number = 1,
  limit: number = 3
) {
  try {
    const { data } = await apolloClient.query<{
      events: EventType[];
      events_aggregated: {
        countDistinct: {
          id: number;
        };
      }[];
    }>({
      query: GET_RELATED_EVENTS_BY_HASHTAG,
      variables: { relatedHashtagArray, originEventId, limit, page }
    });
    return {
      events: data.events,
      totalCount: data.events_aggregated[0].countDistinct.id
    };
  } catch (error) {
    console.log(error);
    // return [];
  }
}

export async function getLatestTopBlog() {
  try {
    const { data } = await apolloClient.query<{ blogs: BlogType[] }>({
      query: GET_LATEST_TOP_BLOG
    });
    return data.blogs[0];
  } catch (error) {
    console.log(error);
  }
}

export async function getAllBlogs(
  topBlogId: string,
  page: number = 1,
  limit: number = 6
) {
  const { data } = await apolloClient.query<{
    blogs: BlogType[];
    blogs_aggregated: BlogsAggregated[];
  }>({
    query: gql(getAllBlogsQuery(topBlogId)),
    variables: {
      topBlogId,
      page,
      limit
    }
  });
  return {
    blogs: data.blogs,
    totalCount: data.blogs_aggregated[0].count.id
  };
}

export async function getAllBlogsPaths() {
  try {
    const { data } = await apolloClient.query<{
      blogs: BlogType[];
    }>({
      query: GET_ALL_BLOGS_PATHS
    });
    return data.blogs.map(p => ({ params: { slug: p.slug } }));
  } catch (error) {
    console.log(error);
  }
}

export async function getBlogBySlug(slug: string) {
  try {
    const { data } = await apolloClient.query<{
      blogs: BlogType;
    }>({
      query: GET_BLOG_BY_SLUG,
      variables: {
        slug
      }
    });
    return data.blogs[0];
  } catch (error) {
    console.log(error);
  }
}

export async function getRelatedBlogsByHashtag(
  relatedHashtagArray: string[],
  originBlogId: string,
  limit: number = 3,
  page: number = 1
) {
  try {
    const { data } = await apolloClient.query<{
      blogs: BlogType[];
      blogs_aggregated: {
        countDistinct: {
          id: number;
        };
      }[];
    }>({
      query: GET_RELATED_BLOGS_BY_HASHTAG,
      variables: { relatedHashtagArray, originBlogId, limit, page }
    });
    return {
      blogs: data.blogs,
      totalCount: data.blogs_aggregated[0].countDistinct.id
    };
  } catch (error) {
    console.log(error);
    // return [];
  }
}

export async function getTotalNumberBlogsByUserID(userID: string) {
  try {
    const { data } = await apolloClient.query<{
      blogs_aggregated: BlogsAggregated[];
    }>({ query: GET_TOTAL_NUMBER_BLOGS_BY_USER_ID, variables: { userID } });
    return data.blogs_aggregated[0].count.id;
  } catch (error) {
    console.log(error);
  }
}

// Post resume to directus adn return file id for the application form
export async function postFileToDirectus(
  formData: FormData,
  additionalHeaders = {}
) {
  const endPoint = `${AppEnv.DIRECTUS_URL}/files`;
  try {
    const { data } = await (axios as AxiosInstance).post(endPoint, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData.getBoundary()}`,
        'Accept-Encoding': 'gzip, deflate',
        Authorization: `Bearer ${process.env.DIRECTUS_TOKEN}`
      }
    });
    return data.data.id;
  } catch (error) {
    console.log(error);
  }
}
