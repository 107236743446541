const HappyDnseBasePath = 'happy-dnse';
const NewsBasePath = '/happy-dnse/tin-tuc';
const EventsBasePath = '/happy-dnse/su-kien';
const BlogsBasePath = '/blog';
export const BasePath = {
  HappyDnseBasePath,
  NewsBasePath,
  EventsBasePath,
  BlogsBasePath
};
