import { gql } from '@apollo/client';

export const GET_SUPER_HOT_OPENING_JOBS = gql`
  query GetSuperHotOpeningJobs($limit: Int) {
    jobs(
      filter: { status: { _eq: "published" }, super_hot: { _eq: true }, end_date: { _gte: "$NOW" } }
      sort: ["-date_created"]
      limit: $limit
    ) {
      id
      status
      title
      description
      work_type
      address
      department
      start_date
      end_date
      start_salary
      end_salary
      currency
      super_hot
      slug
      skills {
        skills_id {
          name
        }
      }
    }
  }
`;

export const getAllOpeningJobsQuery = (
  address?: string[],
  workType?: string[],
  department?: string[],
  skill?: string[],
  title?: string
) => {
  return `
        query getAllOpeningJobs (
            ${address && address?.length > 0 ? '$address: [String]' : ''}
            ${workType && workType?.length > 0 ? '$workType: [String]' : ''}
            ${
              department && department?.length > 0
                ? '$department: [String]'
                : ''
            }
            ${skill && skill?.length > 0 ? '$skill: [String]' : ''}
            ${title && title?.length > 0 ? ' $title: String' : ''}
            $page: Int
            $limit: Int
        ){
            jobs(
                filter: {
                    _or: [
                        {
                            ${
                              address && address?.length > 0
                                ? 'address: { _in: $address }'
                                : ''
                            }
                            ${
                              workType && workType?.length > 0
                                ? 'work_type: { _in: $workType }'
                                : ''
                            }
                            ${
                              department && department?.length > 0
                                ? 'department: { _in: $department }'
                                : ''
                            }
                            ${
                              skill && skill?.length > 0
                                ? 'skills: { skills_id: { name: { _in: $skill } } }'
                                : ''
                            }
                            ${
                              title && title?.length > 0
                                ? 'title: { _icontains: $title }'
                                : ''
                            }
                            status: { _eq: "published" }
                            end_date: { _gte: "$NOW" }
                        }
                        {
                            ${
                              address && address?.length > 0
                                ? 'address: { _nin: ["Hà Nội", "Hồ Chí Minh"] }'
                                : ''
                            }
                            ${
                              workType && workType?.length > 0
                                ? 'work_type: { _in: $workType }'
                                : ''
                            }
                            ${
                              department && department?.length > 0
                                ? 'department: { _in: $department }'
                                : ''
                            }
                            ${
                              skill && skill?.length > 0
                                ? 'skills: { skills_id: { name: { _in: $skill } } }'
                                : ''
                            }
                            ${
                              title && title?.length > 0
                                ? 'title: { _icontains: $title }'
                                : ''
                            }
                            status: { _eq: "published" }
                            end_date: { _gte: "$NOW" }
                        }
                    ]
                }
                sort: ["-date_created"]
                page: $page
                limit: $limit
            ){
                id
                status
                title
                description
                work_type
                address
                department
                start_date
                end_date
                start_salary
                end_salary
                currency
                super_hot
                slug
                skills {
                    skills_id {
                        name
                    }
                }
            }
            jobs_aggregated(
                 filter: {
                    _or: [
                        {
                            ${
                              address && address?.length > 0
                                ? 'address: { _in: $address }'
                                : ''
                            }
                            ${
                              workType && workType?.length > 0
                                ? 'work_type: { _in: $workType }'
                                : ''
                            }
                            ${
                              department && department?.length > 0
                                ? 'department: { _in: $department }'
                                : ''
                            }
                            ${
                              skill && skill?.length > 0
                                ? 'skills: { skills_id: { name: { _in: $skill } } }'
                                : ''
                            }
                            ${
                              title && title?.length > 0
                                ? 'title: { _icontains: $title }'
                                : ''
                            }
                            status: { _eq: "published" }
                            end_date: { _gte: "$NOW" }
                        }
                        {
                            ${
                              address && address?.length > 0
                                ? 'address: { _nin: ["Hà Nội", "Hồ Chí Minh"] }'
                                : ''
                            }
                            ${
                              workType && workType?.length > 0
                                ? 'work_type: { _in: $workType }'
                                : ''
                            }
                            ${
                              department && department?.length > 0
                                ? 'department: { _in: $department }'
                                : ''
                            }
                            ${
                              skill && skill?.length > 0
                                ? 'skills: { skills_id: { name: { _in: $skill } } }'
                                : ''
                            }
                            ${
                              title && title?.length > 0
                                ? 'title: { _icontains: $title }'
                                : ''
                            }
                            status: { _eq: "published" }
                            end_date: { _gte: "$NOW" }
                        }
                    ]
                }
            ){
                count {
                    id
                }
            }
        }
    `;
};

export const GET_ALL_OPENING_DEPARTMENTS = gql`
  query GetAllOpeningDepartments {
    jobs(filter: { status: { _eq: "published" }, end_date: { _gte: "$NOW" }}) {
      department
    }
  }
`;
export const GET_RECOMMEND_SKILLS = gql`
  query Jobs_skills {
    jobs_skills {
      skills_id {
        name
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_OPENING_JOBS = gql`
  query GetTotalNumberOpeningJob {
    jobs_aggregated(filter: { status: { _eq: "published" }, end_date: { _gte: "$NOW" } }) {
      count {
        id
      }
    }
  }
`;

export const GET_ALL_JOBS_PATHS = gql`
  query GetAllJobsPaths {
    jobs(filter: { status: { _eq: "published" }, end_date: { _gte: "$NOW" } }) {
      slug
    }
  }
`;

export const GET_JOB_BY_SLUG = gql`
  query GetJobsBySlug($slug: String) {
    jobs(filter: { slug: { _eq: $slug } }) {
      id
      status
      date_created
      date_updated
      title
      description
      department
      start_date
      end_date
      start_salary
      end_salary
      currency
      work_type
      super_hot
      address
      skills {
        skills_id {
          name
        }
      }
      slug
      job_description
      job_requirement
      job_benefit
    }
  }
`;

export const GET_ALL_EVENTS_QUERY = gql`
  query GetAllEvents($page: Int, $limit: Int) {
    events(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      page: $page
      limit: $limit
    ) {
      id
      title
      content
      sapo
      date_created
      cover_image {
        id
        width
        height
      }
      slug
    }
    events_aggregated(filter: { status: { _eq: "published" } }) {
      count {
        id
      }
    }
  }
`;

export const GET_HOT_NEWS = gql`
  query GetFiveLatestHotNews($limit: Int) {
    news(
      filter: { hottest: { _eq: true }, status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: $limit
    ) {
      id
      status
      slug
      user_created {
        id
        first_name
        last_name
        avatar {
          id
          width
          height
        }
      }
      title
      content
      sapo
      date_created
      cover_image {
        id
        width
        height
      }
    }
  }
`;

export const GET_ALL_NEWS_QUERY = gql`
  query GetAllNews(
    $latestHotNewsIds: [GraphQLStringOrFloat]
    $page: Int
    $limit: Int
  ) {
    news(
      page: $page
      limit: $limit
      filter: {
        status: { _eq: "published" }
        id: { _nin: $latestHotNewsIds }
      }
      sort: ["-date_created"]
    ) {
      id
      title
      slug
      content
      sapo
      date_created
      cover_image {
        id
        width
        height
      }
      user_created {
        id
        first_name
        last_name
        avatar {
          id
          width
          height
        }
      }
      hashtag {
        id
        hashtag_id {
          name
        }
      }
    }
    news_aggregated(
      filter: {
        status: { _eq: "published" }
        id: { _nin: $latestHotNewsIds }
      }
    ) {
      count {
        id
      }
    }
  }
`;

export const GET_ALL_NEWS_PATHS = gql`
  query GetAllNewsPaths {
    news(filter: { status: { _eq: "published" } }) {
      slug
    }
  }
`;

export const GET_NEWS_BY_SLUG = gql`
  query GetNewsBySlug($slug: String) {
    news(filter: { slug: { _eq: $slug } }) {
      user_created {
        id
        first_name
        last_name
        avatar {
          id
          width
          height
        }
      }
      id
      date_created
      title
      content
      sapo
      slug
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_NEWS_BY_USER_ID = gql`
  query GetTotalNumberNewsByUserId($userID: String) {
    news_aggregated(filter: { user_created: { id: { _eq: $userID } } }) {
      count {
        id
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_EVENTS_BY_USER_ID = gql`
  query GetTotalNumberEventsByUserId($userID: String) {
    events_aggregated(filter: { user_created: { id: { _eq: $userID } } }) {
      count {
        id
      }
    }
  }
`;

export const GET_ALL_EVENTS_PATHS = gql`
  query GetAllEventsPaths {
    events(filter: { status: { _eq: "published" } }) {
      slug
    }
  }
`;
export const GET_EVENT_BY_SLUG = gql`
  query GetEventBySlug($slug: String) {
    events(filter: { slug: { _eq: $slug } }) {
      user_created {
        id
        first_name
        last_name
        avatar {
          id
          width
          height
        }
      }
      id
      date_created
      title
      content
      sapo
      slug
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
  }
`;

export const GET_TOP_EVENTS = gql`
  query GetTopEvent($limit: Int) {
    events(
      filter: { isTop: { _eq: true }, status: { _eq: "published" } }
      limit: $limit
      sort: ["-date_created"]
    ) {
      title
      sapo
      content
      cover_image {
        id
        width
        height
      }
      slug
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
      date_created
    }
  }
`;

export const GET_STAR_EVENTS = gql`
  query GetStarEvents($limit: Int) {
    events(
      filter: { star: { _eq: true }, status: { _eq: "published" } }
      limit: $limit
      sort: ["-date_created"]
    ) {
      id
      status
      slug
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
  }
`;

export const GET_RELATED_NEWS_BY_HASHTAG = gql`
  query GetRelatedNewsByHashtag(
    $relatedHashtagArray: [String]
    $originNewsId: GraphQLStringOrFloat
    $limit: Int
    $page: Int
  ) {
    news(
      limit: $limit
      page: $page
      sort: ["-date_created"]
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originNewsId }
        status: { _eq: "published" }
      }
    ) {
      id
      status
      date_created
      date_updated
      title
      content
      sapo
      hottest
      slug
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
    news_aggregated(
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originNewsId }
        status: { _eq: "published" }
      }
    ) {
      countDistinct {
        id
      }
    }
  }
`;

export const GET_RELATED_EVENTS_BY_HASHTAG = gql`
  query GetRelatedEventsByHashtag(
    $relatedHashtagArray: [String]
    $originEventId: GraphQLStringOrFloat
    $limit: Int
    $page: Int
  ) {
    events(
      limit: $limit
      page: $page
      sort: ["-date_created"]
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originEventId }
        status: { _eq: "published" }
      }
    ) {
      id
      status
      date_created
      date_updated
      title
      content
      sapo
      slug
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
    events_aggregated(
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originEventId }
        status: { _eq: "published" }
      }
    ) {
      countDistinct {
        id
      }
    }
  }
`;

export const GET_ALL_BLOGS = gql`
  query GetAllBlogs($topBlogId: GraphQLStringOrFloat, $page: Int, $limit: Int) {
    blogs(
      filter: { id: { _neq: $topBlogId }, status: { _eq: "published" } }
      sort: ["-date_created"]
      page: $page
      limit: $limit
    ) {
      id
      status
      date_created
      date_updated
      title
      slug
      content
      sapo
      cover_image {
        id
      }
    }
    blogs_aggregated(
      filter: { id: { _neq: $topBlogId }, status: { _eq: "published" } }
    ) {
      count {
        id
      }
    }
  }
`;

export const getAllBlogsQuery = (topBlogId?: string) => {
  return `
     query GetAllBlogs(
     ${topBlogId ? '$topBlogId: GraphQLStringOrFloat,' : ''}
     $page: Int,
     $limit: Int
     ) {
      blogs(
        filter: { ${
          topBlogId ? 'id: { _neq: $topBlogId },' : ''
        }, status: { _eq: "published" } }
        sort: ["-date_created"]
        page: $page
        limit: $limit
      ) {
        id
        status
        date_created
        date_updated
        title
        slug
        content
        sapo
        cover_image {
          id
        }
      }
      blogs_aggregated(
        filter: { id: { _neq: $topBlogId }, status: { _eq: "published" } }
      ) {
        count {
          id
        }
      }
    }
   `;
};

export const GET_LATEST_TOP_BLOG = gql`
  query GetLatestTopBlog {
    blogs(
      filter: { isTop: { _eq: true }, status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 1
    ) {
      id
      status
      date_created
      date_updated
      title
      slug
      content
      sapo
      cover_image {
        id
      }
    }
  }
`;

export const GET_ALL_BLOGS_PATHS = gql`
  query GetAllBlogsPaths {
    blogs (filter: { status: { _eq: "published" } }) {
      slug
    }
  }
`;

export const GET_BLOG_BY_SLUG = gql`
  query GetBlogBySlug($slug: String) {
    blogs (filter: { slug: { _eq: $slug } }) {
      id
      user_created {
        id
        first_name
        last_name
        avatar {
          id
          width
          height
        }
      }
      status
      date_created
      date_updated
      title
      slug
      content
      sapo
      cover_image {
        id
        width
        height
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
  }
`;

export const GET_RELATED_BLOGS_BY_HASHTAG = gql`
  query GetRelatedBlogsByHashtag(
    $relatedHashtagArray: [String]
    $originBlogId: GraphQLStringOrFloat
    $limit: Int
    $page: Int
  ) {
    blogs(
      limit: $limit
      page: $page
      sort: ["-date_created"]
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originBlogId }
        status: { _eq: "published" }
      }
    ) {
      id
      status
      date_created
      date_updated
      title
      slug
      content
      sapo
      cover_image {
        id
      }
      hashtag {
        id
        hashtag_id {
          id
          name
        }
      }
    }
    blogs_aggregated(
      filter: {
        hashtag: { hashtag_id: { name: { _in: $relatedHashtagArray } } }
        id: { _neq: $originBlogId }
        status: { _eq: "published" }
      }
    ) {
      countDistinct {
        id
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_BLOGS_BY_USER_ID = gql`
  query GetTotalNumberBlogsByUserId($userID: String) {
    blogs_aggregated(filter: { user_created: { id: { _eq: $userID } } }) {
      count {
        id
      }
    }
  }
`;
